export const privacyPolicyHtmlFr = `
<div>
   <p>Merci d'avoir choisi Paylican SA (“<strong>Société</strong>”, “<strong>nous</strong>”, “<strong>notre</strong>”, or “<strong>notres</strong>”). Nous nous engageons à protéger vos informations personnelles et votre droit à la vie privée. Si vous avez des questions ou des préoccupations concernant cet avis de confidentialité ou nos pratiques en ce qui concerne vos informations personnelles, veuillez nous contacter <a href="/en/contact/">ici</a> .</p>
   <p>Lorsque vous utilisez notre application mobile, selon le cas (l '"<strong>App</strong>") et plus généralement, utilisez l'un de nos services (les "<strong>Services</strong>", qui incluent l'application), nous sommes conscients que vous nous confiez vos informations personnelles . Nous prenons vos données privées très au sérieux. Dans cet avis de confidentialité, nous cherchons à vous expliquer de la manière la plus claire possible quelles informations nous collectons, comment nous les utilisons et quels droits vous avez à cet égard. Nous espérons que vous prendrez le temps de le lire attentivement, car il est important. S'il y a des conditions dans cet avis de confidentialité avec lesquelles vous n'êtes pas d'accord, veuillez cesser immédiatement d'utiliser nos services.</p>
   <p>Cet avis de confidentialité s'applique à toutes les informations collectées via nos services (qui, comme décrit ci-dessus, comprend notre application), ainsi qu'à tous les services, ventes, marketing ou événements associés.</p>
   <p><strong>Veuillez lire attentivement cet avis de confidentialité car il vous aidera à comprendre ce que nous faisons avec les informations que nous collectons.</strong></p>
   <p><strong>TABLE DES MATIÈRES</strong></p>
   <p><a href="#infocollect">1. QUELLES INFORMATIONS COLLECTONS-NOUS?</a></p>
   <p><a href="#infouse">2. COMMENT UTILISONS-NOUS VOS INFORMATIONS?</a></p>
   <p><a href="#infoshare">3. VOS INFORMATIONS SERONT-ELLES PARTAGÉES AVEC QUELQU'UN?</a></p>
   <p><a href="#cookies">4. UTILISONS-NOUS DES COOKIES ET AUTRES TECHNOLOGIES DE SUIVI?</a></p>
   <p><a href="#intltransfers">5. VOS INFORMATIONS SONT-ELLES TRANSFÉRÉES À L'INTERNATIONAL?</a></p>
   <p><a href="#inforetain">6. COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS?</a></p>
   <p><a href="#infosafe">7. COMMENT GARDONS-NOUS VOS INFORMATIONS EN SÉCURITÉ?</a></p>
   <p><a href="#infominors">8. RECUEILLONS-NOUS DES INFORMATIONS DE MINEURS?</a></p>
   <p><a href="#privacyrights">9. QUELS SONT VOS DROITS À LA VIE PRIVÉE?</a></p>
   <p><a href="#DNT">10. COMMANDES POUR LES FONCTIONNALITÉS «NE PAS SUIVRE»</a></p>
   <p><a href="#caresidents">11. LES RÉSIDENTS DE CALIFORNIE ONT-ILS DES DROITS SPECIFIQUES À LA VIE PRIVÉE?</a></p>
   <p><a href="#policyupdates">12. FOURNISSONS-NOUS DES MISES À JOUR DE CET AVIS?</a></p>
   <p><a href="#contact">13. COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CET AVIS?</a></p>
   <p id="infocollect"><strong>1. QUELLES INFORMATIONS COLLECTONS-NOUS?</strong></p>
   <p><strong><strong>Informations collectées automatiquement</strong></strong></p>
   <p><strong>En bref:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our App .</p>
   <p>Nous recueillons automatiquement certaines informations lorsque vous visitez, utilisez ou naviguez sur l'application. Ces informations ne révèlent pas votre identité spécifique (comme votre nom ou vos coordonnées), mais peuvent inclure des informations sur l'appareil et l'utilisation, telles que votre adresse IP, le navigateur et les caractéristiques de l'appareil, le système d'exploitation, les préférences linguistiques, les URL de référence, le nom de l'appareil, le pays, l'emplacement , des informations sur qui et quand vous utilisez notre application et d'autres informations techniques. Ces informations sont principalement nécessaires pour maintenir la sécurité et le fonctionnement de notre application, ainsi que pour nos analyses internes et nos rapports.</p>
   <p>Comme de nombreuses entreprises, nous collectons également des informations via des cookies et des technologies similaires.</p>
   <p>Les informations que nous collectons comprennent:</p>
   <ul>
      <li>Données de journal et d'utilisation. Les données de journal et d'utilisation sont des informations relatives au service, à l'utilisation de diagnostic et aux performances que nos serveurs collectent automatiquement lorsque vous accédez ou utilisez notre application et que nous enregistrons dans des fichiers journaux. En fonction de la façon dont vous interagissez avec nous, ces données de journal peuvent inclure votre adresse IP, les informations sur l'appareil, le type de navigateur, les paramètres et des informations sur votre activité dans l'application (telles que les dates/horodatages associés à votre utilisation, les pages et les fichiers consultés, recherches et autres actions que vous effectuez, telles que les fonctionnalités que vous utilisez), les informations sur les événements de l'appareil (telles que l'activité du système, les rapports d'erreur (parfois appelés «vidages sur incident») et les paramètres du matériel informatique).</li>
   </ul>
   <ul>
      <li>Données de l'appareil. Nous recueillons des données sur les appareils telles que des informations sur votre ordinateur, téléphone, tablette ou autre appareil que vous utilisez pour accéder à l'application. En fonction de l'appareil utilisé, les données de cet appareil peuvent inclure des informations telles que votre adresse IP (ou serveur proxy), les numéros d'identification de l'application de l'appareil, l'emplacement, le type de navigateur, le modèle de matériel informatique, fournisseur de services Internet et / ou opérateur de téléphonie mobile, les informations de configuration du système d'exploitation.</li>
   </ul>
   <ul>
      <li>Données de localisation. Nous collectons des informations telles que des informations sur l'emplacement de votre appareil, qui peuvent être précises ou imprécises. La quantité d'informations que nous collectons dépend du type de paramètres de l'appareil que vous utilisez pour accéder à l'application. Par exemple, nous pouvons utiliser le GPS et d'autres technologies pour collecter des données de géolocalisation qui nous indiquent votre emplacement actuel (en fonction de votre adresse IP). Vous pouvez refuser de nous autoriser à collecter ces informations soit en refusant l'accès aux informations, soit en désactivant vos paramètres de localisation sur votre appareil. Notez cependant que si vous choisissez de vous désinscrire, vous ne pourrez peut-être pas utiliser certains aspects des Services.</li>
   </ul>
   <p><strong><strong>Informations collectées via notre application</strong></strong></p>
   <p><strong>En bref:</strong> nous recueillons des informations concernant votre appareil mobile, les notifications push, lorsque vous utilisez notre application.</p>
   <p>Si vous utilisez notre application, nous recueillons également les informations suivantes:</p>
   <ul>
      <li>Données de l'appareil mobile. Nous collectons automatiquement des informations sur l'appareil (comme l'identifiant de votre appareil mobile, le modèle et le fabricant), le système d'exploitation, les informations de version et les informations de configuration du système, les numéros d'identification de l'appareil et de l'application, le type et la version du navigateur, le modèle de matériel informatique, fournisseur de services Internet et / ou opérateur de téléphonie mobile, et adresse IP (Internet Protocol) (ou serveur proxy). Si vous utilisez notre application, nous pouvons également collecter des informations sur le réseau téléphonique associé à votre appareil mobile, le système d'exploitation ou la plate-forme de votre appareil mobile, le type d'appareil mobile que vous utilisez, l'ID unique de votre appareil mobile et des informations sur les fonctionnalités de notre application auxquelles vous avez accédé.</li>
   </ul>
   <ul>
      <li>Notifications push. Nous pouvons vous demander de vous envoyer des notifications push concernant votre compte ou certaines fonctionnalités de l'application. Si vous souhaitez refuser de recevoir ces types de communications, vous pouvez les désactiver dans les paramètres de votre appareil.</li>
   </ul>
   <p>Les informations sont principalement nécessaires pour maintenir la sécurité et le fonctionnement de notre application, pour le dépannage et pour nos analyses internes et nos rapports.</p>
   <p id="infouse"><strong>2. COMMENT UTILISONS-NOUS VOS INFORMATIONS?</strong></p>
   <p><strong>En bref:</strong> Nous traitons vos informations à des fins basées sur des intérêts commerciaux légitimes, l'exécution de notre contrat avec vous, le respect de nos obligations légales et / ou votre consentement.</p>
   <p>Nous utilisons les informations personnelles collectées via notre application à diverses fins commerciales décrites ci-dessous. Nous traitons vos informations personnelles à ces fins en fonction de nos intérêts commerciaux légitimes, afin de conclure ou d'exécuter un contrat avec vous, avec votre consentement, et / ou pour nous conformer à nos obligations légales. Nous indiquons les motifs de traitement spécifiques sur lesquels nous nous appuyons à côté de chaque objectif énuméré ci-dessous.</p>
   <p>Nous utilisons les informations que nous collectons ou recevons:</p>
   <ul>
      <li><strong>Exécutez et gérez vos commandes.</strong> Nous pouvons utiliser vos informations pour exécuter et gérer vos commandes, paiements, retours et échanges effectués via l'application.</li>
      <li><strong>Administrer les tirages au sort et les concours.</strong> Nous pouvons utiliser vos informations pour administrer des tirages au sort et des concours lorsque vous choisissez de participer à nos concours.</li>
      <li><strong>Fournir et faciliter la fourniture de services à l'utilisateur.</strong> Nous pouvons utiliser vos informations pour vous fournir le service demandé.</li>
      <li><strong>Répondre aux demandes des utilisateurs / offrir une assistance aux utilisateurs.</strong> Nous pouvons utiliser vos informations pour répondre à vos demandes et résoudre tout problème potentiel que vous pourriez rencontrer avec l'utilisation de nos Services.</li>
      <li><strong>A d'autres fins commerciales.</strong> Nous pouvons utiliser vos informations à d'autres fins commerciales, telles que l'analyse de données, l'identification des tendances d'utilisation, la détermination de l'efficacité de nos campagnes promotionnelles et pour évaluer et améliorer notre application, nos produits, notre marketing et votre expérience. Nous pouvons utiliser et stocker ces informations sous une forme agrégée et anonyme afin qu'elles ne soient pas associées à des utilisateurs finaux individuels et n'incluent pas d'informations personnelles. Nous n'utiliserons pas d'informations personnelles identifiables sans votre consentement.</li>
   </ul>
   <p id="infoshare"><strong>3. VOS INFORMATIONS SERONT-ELLES PARTAGÉES AVEC QUELQU'UN?</strong></p>
   <p><strong>En bref:</strong> nous ne partageons des informations qu'avec votre consentement, pour nous conformer aux lois, pour vous fournir des services, pour protéger vos droits ou pour remplir des obligations commerciales.</p>
   <p>Nous pouvons traiter ou partager vos données que nous détenons sur la base juridique suivante:</p>
   <ul>
      <li><strong>Consentement:</strong> nous pouvons traiter vos données si vous nous avez donné un consentement spécifique pour utiliser vos informations personnelles dans un but précis.</li>
      <li><strong>Intérêts légitimes:</strong> nous pouvons traiter vos données lorsque cela est raisonnablement nécessaire pour réaliser nos intérêts commerciaux légitimes.</li>
      <li><strong>Exécution d'un contrat:</strong> lorsque nous avons conclu un contrat avec vous, nous pourrions traiter vos informations personnelles pour remplir les conditions de notre contrat.</li>
      <li><strong>Obligations légales:</strong> Nous pourrions divulguer vos informations lorsque nous sommes légalement tenus de le faire afin de nous conformer à la loi applicable, aux demandes gouvernementales, à une procédure judiciaire, à une décision judiciaire ou à une procédure judiciaire, par exemple en réponse à une ordonnance du tribunal ou à une assignation à comparaître (y compris en réponse aux autorités publiques pour répondre aux exigences de sécurité nationale ou d'application de la loi).</li>
      <li><strong>Intérêts vitaux:</strong> nous pourrions divulguer vos informations lorsque nous estimons qu'il est nécessaire d'enquêter, d’empêcher ou de prendre des mesures concernant des violations potentielles de nos politiques, des soupçons de fraude, des situations impliquant des menaces potentielles pour la sécurité de toute personne et des activités illégales, ou comme preuve dans les litiges dans lesquels nous sommes impliqués.</li>
   </ul>
   <p>Plus précisément, nous pouvons avoir besoin de traiter vos données ou de partager vos informations personnelles dans les situations suivantes:</p>
   <ul>
      <li><strong>Transferts d'entreprise:</strong> Nous pourrions partager ou transférer vos informations dans le cadre ou pendant les négociations de toute fusion, vente d'actifs de l'entreprise, financement ou acquisition de tout ou partie de nos activités à une autre société.</li>
   </ul>
   <ul>
      <li><strong>Partenaires d'affaires:</strong> Nous pouvons partager vos informations avec nos partenaires commerciaux pour vous proposer certains produits, services ou promotions.</li>
   </ul>
   <p id="cookies"><strong>4. UTILISONS-NOUS DES COOKIES ET AUTRES TECHNOLOGIES DE SUIVI?</strong></p>
   <p><strong>En bref:</strong> nous pourrions utiliser des cookies et autres technologies de suivi pour collecter et stocker vos informations.</p>
   <p>Nous pouvons utiliser des cookies et des technologies de suivi similaires (comme des balises Web et des pixels) pour accéder ou stocker des informations. Des informations spécifiques sur la manière dont nous utilisons ces technologies et comment vous pouvez refuser certains cookies sont présentées dans notre Avis sur les Cookies.</p>
   <p id="intltransfers"><strong>5. VOS INFORMATIONS SONT-ELLES TRANSFÉRÉES À L'INTERNATIONAL?</strong></p>
   <p><strong>En bref:</strong> nous pourrions transférer, stocker et traiter vos informations dans des pays autres que le vôtre.</p>
   <p>Si vous accédez à notre application à partir d’un autre pays, sachez que vos informations peuvent être transférées, stockées et traitées par nous-mêmes dans nos installations (qui sont situés dans différents centres de données dans plusieurs pays) et par les tiers avec lesquels nous pourrions partager vos informations personnelles (voir " <a href="#infoshare">VOS INFORMATIONS SERONT-ELLES PARTAGÉES AVEC QUELQU’UN ?</a> " ci-dessus), dans d'autres pays.</p>
   <p>Si vous résidez dans l'Espace Economique Européen, alors ces pays ne disposent pas nécessairement de lois sur la protection des données ou d'autres lois similaires aussi complètes que celles de votre pays. Nous prendrons cependant toutes les mesures nécessaires pour protéger vos informations personnelles conformément à cet avis de confidentialité et à la loi applicable. </p>
   
   <p id="inforetain"><strong>6. COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS?</strong></p>
   <p><strong>En bref:</strong> nous conservons vos informations aussi longtemps que nécessaire pour atteindre les objectifs décrits dans cet avis de confidentialité, sauf disposition contraire de la loi.</p>
   <p>Nous conserverons vos informations personnelles uniquement le temps nécessaire aux fins énoncées dans le présent avis de confidentialité, à moins qu'une période de conservation plus longue ne soit requise ou autorisée par la loi (comme les exigences fiscales, comptables ou autres exigences légales). Dans aucun cas cet avis ne nous obligera à conserver vos informations personnelles pendant plus de cent vingt (120) mois après la résiliation du compte de l'utilisateur.</p>
   <p>Lorsque nous n'avons aucun besoin commercial légitime en cours de traiter vos informations personnelles, soit nous supprimerons ces informations soit nous les rendrons anonymes, ou, si cela n'est pas possible (par exemple, parce que vos informations personnelles ont été stockées dans des archives de sauvegarde), alors nous conserverons vos informations personnelles et les isolerons de tout traitement ultérieur jusqu'à ce que la suppression soit possible.</p>
   
   <p id="infosafe"><strong>7. COMMENT GARDONS-NOUS VOS INFORMATIONS EN SÉCURITÉ?</strong></p>
   <p><strong>En bref:</strong> Nous visons à protéger vos informations personnelles grâce à un système de mesures de sécurité organisationnelles et techniques.</p>
   <p>Nous avons mis en place des mesures de sécurité techniques et organisationnelles appropriées conçues pour protéger la sécurité de toutes les informations personnelles que nous traitons. Cependant, malgré nos mesures de protection et nos efforts pour sécuriser vos informations, aucune transmission électronique sur Internet ou technologie de stockage d'informations ne peut être garantie à 100%, nous ne pouvons donc pas promettre ou garantir que les pirates informatiques, les cybercriminels ou d'autres tiers non autorisés ne seront pas capables de mettre en échec notre sécurité et de collecter, accéder, voler ou modifier de manière inappropriée vos informations. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, la transmission d'informations personnelles vers et depuis notre application se fait à vos propres risques. Vous ne devriez accéder à l'application que dans un environnement sécurisé.</p>
   
   <p id="infominors"><strong>8. RECUEILLONS-NOUS DES INFORMATIONS DE MINEURS?</strong></p>
   <p><strong>En bref:</strong> nous ne collectons pas sciemment de données ni ne les commercialisons auprès d'enfants de moins de 18 ans.</p>
   <p>Nous ne sollicitons pas sciemment de données ni ne les commercialisons auprès d'enfants de moins de 18 ans. En utilisant l'application, vous déclarez que vous avez au moins 18 ans ou que vous êtes le parent ou le tuteur d'un tel mineur et que vous consentez à l'utilisation de l'application par cette personne à charge mineure. Si nous apprenons que des informations personnelles d'utilisateurs de moins de 18 ans ont été collectées, nous désactiverons le compte et prendrons des mesures raisonnables pour supprimer rapidement ces données de nos archives. Si vous avez connaissance de données que nous avons pu collecter auprès d'enfants de moins de 18 ans, veuillez nous contacter <a href="/en/contact/">ici</a>.</p>
   
   <p id="privacyrights"><strong>9. QUELS SONT VOS DROITS À LA VIE PRIVÉE?</strong></p>
   <p><strong>En bref:</strong> vous pouvez revoir, modifier ou résilier votre compte à tout moment.</p>
   <p>Si vous résidez dans l'Espace Economique Européen et que vous pensez que nous traitons illégalement vos informations personnelles, vous avez également le droit de déposer une plainte auprès de votre autorité locale de contrôle de la protection des données. Vous pouvez trouver leurs coordonnées ici : <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</p>
   <p>Si vous résidez en Suisse, les coordonnées des autorités de protection des données sont disponibles ici: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</p>
   <p>Si vous avez des questions ou des commentaires sur vos droits à la confidentialité, vous pouvez nous contacter <a href="/en/contact/">ici</a> .</p>
   <p><strong>Information sur le compte</strong></p>
   <p>Si vous souhaitez à tout moment consulter ou modifier les informations de votre compte ou résilier votre compte, vous pouvez:</p>
   <p>■ nous contacter en utilisant les coordonnées fournies.</p>
   <p>À votre demande de résiliation de votre compte, nous désactiverons ou supprimerons votre compte et vos informations de nos bases de données actives. Cependant, nous pourrions conserver certaines informations dans nos fichiers pour prévenir la fraude, résoudre les problèmes, aider à toute enquête, appliquer nos conditions d'utilisation et / ou se conformer aux exigences légales applicables.</p>
   <p><strong>Cookies et technologies similaires:</strong> la plupart des navigateurs Web sont configurés pour accepter les cookies par défaut. Si vous préférez, vous pouvez généralement choisir de configurer votre navigateur pour supprimer et refuser les cookies. Si vous choisissez de supprimer ou de refuser les cookies, cela pourrait affecter certaines fonctionnalités ou services de notre application. Pour désactiver la publicité ciblée par centres d'intérêt des annonceurs sur notre application, visitez <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> .</p>
   <p><strong>Désabonnement du marketing par e-mail:</strong> vous pouvez à tout moment vous désinscrire de notre liste d'e-mails marketing en cliquant sur le lien de désabonnement dans les e-mails que nous envoyons ou en nous contactant à l'aide des coordonnées fournies ci-dessous. Vous serez alors retiré de la liste de diffusion marketing - cependant, nous pourrions toujours communiquer avec vous, par exemple pour vous envoyer des e-mails liés au service qui sont nécessaires à l'administration et à l'utilisation de votre compte, pour répondre à des demandes de service ou à d’autres fins non commerciales. Pour vous désinscrire autrement, vous pouvez:</p>
   <p>■ nous contacter en utilisant les coordonnées fournies.</p>
   
   <p id="DNT"><strong>10. COMMANDES POUR LES FONCTIONNALITÉS «NE PAS SUIVRE»</strong></p>
   <p>La plupart des navigateurs Web et certains systèmes d'exploitation mobiles et applications mobiles incluent une fonction ou un paramètre Do-Not-Track («DNT») que vous pouvez activer pour signaler votre préférence en matière de confidentialité de ne pas avoir de données sur vos activités de navigation en ligne surveillées et collectées. À ce stade, aucune norme technologique uniforme pour la reconnaissance et la mise en œuvre des signaux DNT n'a été finalisée. En tant que tel, nous ne répondons actuellement pas aux signaux du navigateur DNT ou à tout autre mécanisme qui communique automatiquement votre choix de ne pas être suivi en ligne. Si une norme de suivi en ligne est adoptée que nous devons suivre à l'avenir, nous vous informerons de cette pratique dans une version révisée de cet avis de confidentialité.</p>
   
   <p id="caresidents"><strong>11. LES RÉSIDENTS DE CALIFORNIE ONT-ILS DES DROITS SPECIFIQUES À LA VIE PRIVÉE?</strong></p>
   <p><strong>En bref:</strong> Oui, si vous êtes un résident de Californie, vous disposez de droits spécifiques concernant l'accès à vos informations personnelles.</p>
   <p>La section 1798.83 du code civil californien, également connue sous le nom de loi «Shine The Light», permet à nos utilisateurs résidant en Californie de demander et d'obtenir de nous, une fois par an et gratuitement, des informations sur les catégories d'informations personnelles (le cas échéant) que nous avons divulguées à des tiers à des fins de marketing direct et les noms et adresses de tous les tiers avec lesquels nous avons partagé des informations personnelles au cours de l'année civile immédiatement précédente. Si vous êtes un résident de Californie et que vous souhaitez faire une telle demande, veuillez nous soumettre votre demande par écrit en utilisant les coordonnées fournies ci-dessous.</p>
   <p>Si vous avez moins de 18 ans, résidez en Californie et possédez un compte enregistré auprès de l'application, vous avez le droit de demander la suppression des données indésirables que vous publiez publiquement sur l'application. Pour demander la suppression de ces données, veuillez nous contacter en utilisant les coordonnées fournies ci-dessous, et inclure l'adresse e-mail associée à votre compte et une déclaration indiquant que vous résidez en Californie. Nous veillerons à ce que les données ne soient pas affichées publiquement sur l'application, mais sachez que les données pourraient ne pas être complètement ou entièrement supprimées de tous nos systèmes (par exemple, sauvegardes, etc.).</p>
   
   <p id="policyupdates"><strong>12. FOURNISSONS-NOUS DES MISES À JOUR DE CET AVIS?</strong></p>
   <p><strong>En bref:</strong> Oui, nous mettrons à jour cet avis si nécessaire pour rester conforme aux lois applicables.</p>
   <p>Nous pourrions mettre à jour cet avis de confidentialité de temps à autre. La version mise à jour sera indiquée par une date de mise à jour «Révisée» et la version mise à jour entrera en vigueur dès qu'elle sera accessible. Si nous apportons des modifications importantes à cet avis de confidentialité, nous pouvons vous en informer soit en affichant bien en évidence un avis de ces modifications, soit en vous envoyant directement une notification. Nous vous encourageons à consulter fréquemment cet avis de confidentialité pour être informé de la manière dont nous protégeons vos informations.</p>
   
   <p id="contact"><strong>13. COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CET AVIS?</strong></p>
   <p>Si vous avez des questions ou des commentaires sur cet avis, vous pouvez nous contacter <a href="/en/contact/">ici</a> ou par courrier à :</p>
   
   <p>Paylican SA</p>
   <p>Carrefour de Rive 1</p>
   <p>Genève 1207</p>
   <p>Suisse</p>
   <p><strong>COMMENT POUVEZ-VOUS EXAMINER, METTRE À JOUR OU SUPPRIMER LES DONNÉES QUE NOUS RECUEILLONS AUPRES DE VOUS?</strong></p>
   <p>En fonction des lois applicables de votre pays, vous pourriez avoir le droit de demander l'accès aux informations personnelles que nous recueillons auprès de vous, de modifier ces informations ou de les supprimer dans certaines circonstances. Pour demander la révision, la mise à jour ou la suppression de vos informations personnelles, veuillez soumettre un formulaire de demande en cliquant ici. Nous répondrons à votre demande dans les 30 jours.</p>
</div>
`;
