import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";
import { privacyPolicyHtml } from "../constants/privacy-policy";
import { privacyPolicyHtmlFr } from "../constants/privacy-policy.fr";

import styles from "./privacy-policy.module.scss";

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "text" }
          slug: { eq: "privacy-policy" }
          language: { eq: $language }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            language
          }
          frontmatter {
            title
            subtitle
          }
        }
      }
    }
  }
`;

const PrivacyPolicyPage = ({ data }) => {
  const { frontmatter, fields } = data.allMarkdownRemark.edges[0].node;
  const { language } = fields;

  const heading = (
    <Heading>
      <h1>{frontmatter.title}</h1>
      <h4>{frontmatter.subtitle}</h4>
    </Heading>
  );

  return (
    <Layout heading={heading}>
      <Head title="Privacy Policy" />
      <div className={styles.container}>
        <div
          dangerouslySetInnerHTML={{
            __html: language === "en" ? privacyPolicyHtml : privacyPolicyHtmlFr,
          }}
        />
      </div>
    </Layout>
  );
};

export default React.memo(PrivacyPolicyPage);
